/*Media*/
.onboarding-wrapper {
  /*Media*/

  @media only screen and (max-width: 1640px) {
    .doubleImg {
      right: -20px;
    }

    /* .luckyMacbookImg {
    width: 950px;
    left: -238px;
  } */

    .luckyMacbookImg {
      width: 858px;
      left: -168px;
    }

    .laptopFrame::before {
      left: -238px;
    }

    .whyWeHereMain {
      padding-top: 156px;
      padding-bottom: 100px;
    }

    .whyWeHereMain {
      background-position-x: 65%;
    }

    .whyWeHereContentInner {
      padding-right: 20%;
    }
  }

  @media only screen and (max-width: 1440px) {
    .commonContentMain .container {
      max-width: 1220px;
    }

    .mobileImg {
      width: 275px;
    }

    .mobileImg.two {
      width: 262px;
    }

    .commonContentInner + .commonContentInner {
      padding-bottom: 110px;
    }

    .whyWeHereMain {
      padding-top: 114px;
      padding-bottom: 68px;
    }

    .freeMusicPromotionInner {
      max-width: 1270px;
    }

    .luckyMacbookImg {
      width: 856px;
      left: -212px;
    }

    .laptopFrame::before {
      left: -284px;
    }
  }

  @media only screen and (max-width: 1299px) {
    .commonContentMain .container {
      max-width: 1140px;
    }

    .doubleImg {
      right: 0;
    }

    .mobileImg {
      width: 256px;
    }

    .mobileImg.two {
      width: 240px;
    }

    .commonContentInner + .commonContentInner .col-md-7.contentGrid {
      padding-left: 50px;
    }

    .commonContentMain::after {
      height: 18px;
    }

    .freeMusicPromotionInner {
      max-width: 1140px;
    }

    .bannerContent h1 {
      font-size: 92px;
    }

    .laptopFrame::before {
      left: -332px;
      height: 508px;
      width: 898px;
      background-size: contain;
    }

    .luckyMacbookImg {
      width: 691px;
      left: -229px;
      top: -40px;
    }
  }

  @media only screen and (max-width: 1275px) {
    /*Free Music Promotion*/
    .freeMusicPromotionInner {
      max-width: 1140px;
    }

    /*Common Content*/
    .luckyMacbookImg {
      width: 670px;
      left: -200px;
    }
    .mobileImg {
      width: 300px;
    }
  }
  @media only screen and (max-width: 1199px) {
    /*Common Padding*/
    .commonPY {
      padding-top: 70px;
      padding-bottom: 70px;
    }
    .commonPT {
      padding-top: 70px;
    }
    .commonPB {
      padding-bottom: 70px;
    }

    /*Header*/
    .logo img {
      width: 160px;
    }
    #header.fixed .logo img {
      width: 120px;
    }

    /*Common Button*/
    .commonButton {
      font-size: 15px;
    }

    /*Sub Heading*/
    .subHeading h3 {
      font-size: 30px;
    }
    .subHeading h4 {
      font-size: 27px;
    }

    /*Banner*/
    .bannerContentPart {
      margin-top: 60px;
    }
    .bannerContent h1 {
      font-size: 78px;
    }
    .bannerContent p,
    .smallTitle > * {
      font-size: 16px;
    }

    /*Fiercely Independent*/
    .fiercelyIndependentGrid h4 {
      font-size: 20px;
    }

    /*Video*/
    /* .videoPart {
    height: 400px;
  } */

    /*Free Music Promotion*/
    .freeMusicPromotionInner {
      max-width: 935px;
    }

    /*Why We Are Here*/
    .whyWeHereMain {
      background-position-x: 65%;
    }
    .siteLogo img {
      width: 150px;
    }

    /*Common Content*/
    .luckyMacbookImg {
      width: 560px;
      left: -180px;
    }
    .mobileImg {
      width: 250px;
    }

    /*Footer*/
    .socialIcons li,
    .footerLinks li {
      margin: 0 18px;
    }
  }

  @media only screen and (max-width: 1199px) {
    /*Common Padding*/
    .commonPY {
      padding-top: 70px;
      padding-bottom: 70px;
    }

    .commonPT {
      padding-top: 70px;
    }

    .commonPB {
      padding-bottom: 70px;
    }

    /*Header*/
    .logo img {
      width: 160px;
    }

    #header.fixed .logo img {
      width: 120px;
    }

    /*Common Button*/
    .commonButton {
      font-size: 20px;
    }

    /*Sub Heading*/
    .subHeading h3 {
      font-size: 36px;
    }

    .subHeading h4 {
      font-size: 28px;
    }

    /*Banner*/
    .bannerContentPart {
      margin-top: 60px;
    }

    .bannerContent h1 {
      font-size: 78px;
    }

    .bannerContent p,
    .smallTitle > * {
      font-size: 20px;
    }

    /*Fiercely Independent*/
    .fiercelyIndependentGrid h4 {
      font-size: 28px;
    }

    /*Video*/
    /* .videoPart {
    height: 400px;
  } */

    /*Free Music Promotion*/
    .freeMusicPromotionInner {
      max-width: 935px;
    }

    /*Why We Are Here*/
    .whyWeHereMain {
      background-position-x: 65%;
    }

    .siteLogo img {
      width: 150px;
    }

    /*Common Content*/
    .luckyMacbookImg {
      width: 610px;
      left: -164px;
    }

    .mobileImg {
      width: 226px;
    }

    .mobileImg.two {
      width: 212px;
    }

    /* .luckyMacbookImg {
    width: 610px;
    left: -164px;
  } */

    .luckyMacbookImg {
      width: 584px;
      left: -163px;
      top: -34px;
    }

    .laptopFrame::before {
      left: -321px;
      height: 430px;
      width: 898px;
      background-size: contain;
    }

    .commonContentMain::after {
      height: 15px;
    }

    /*Footer*/
    .socialIcons li,
    .footerLinks li {
      margin: 0 18px;
    }

    .contentContent h4 {
      font-size: 36px;
    }

    .commonContentInner + .commonContentInner {
      padding-bottom: 80px;
    }

    .whyWeHereMain {
      padding-top: 114px;
      padding-bottom: 68px;
    }
  }

  @media (min-width: 992px) {
    .mobileEpk .col-lg-11 {
      width: 97.52%;
    }
  }
  @media only screen and (max-width: 991px) {
    li,
    p {
      font-size: 16px;
    }

    p {
      line-height: 22px;
    }

    /*Common Padding*/
    .smallPY {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .smallPT {
      padding-top: 30px;
    }

    .smallPB {
      padding-bottom: 30px;
    }

    .commonPY {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    .commonPT {
      padding-top: 50px;
    }

    .commonPB {
      padding-bottom: 50px;
    }

    /*Common Button*/
    .commonButton {
      font-size: 16px;
      padding: 10px 40px 8px;
    }

    .commonButton--wide {
      padding-left: 60px;
      padding-right: 60px;
    }

    /*Sub Heading*/
    .subHeading h3 {
      font-size: 28px;
    }

    .subHeading h4 {
      font-size: 22px;
    }

    /*Header*/
    .logo img {
      width: 130px;
    }

    #header.fixed .logo img {
      width: 110px;
    }

    #header.fixed .navigationInner li {
      font-size: 13px;
    }

    /*Banner*/
    .bannerContentPart {
      margin-top: 60px;
    }

    .bannerContent h1 {
      font-size: 68px;
    }

    /*Fiercely Independent*/
    .bannerContent p,
    .smallTitle > * {
      font-size: 15px;
    }

    .fiercelyIndependentGrid h4 {
      font-size: 18px;
    }

    /*Free Music Promotion*/
    .freeMusicPromotionInner {
      max-width: 695px;
    }

    /*Why We Are Here*/
    .whyWeHereContent h3 {
      font-size: 34px;
      margin-bottom: 20px;
    }

    .siteLogo img {
      width: 130px;
    }

    /*Free Music Promotion*/
    .videoPart {
      top: 60px;
      /* height: 330px; */
    }

    /*Common Content*/
    .luckyMacbookImg {
      width: 54%;
      left: -100px;
    }

    .mobileImg {
      width: 200px;
    }

    .mobileImg.two {
      bottom: 20px;
    }

    /*Common Content*/
    /* .commonContentMain {
    padding-top: 100px;
  } */
    .contentContent h4 {
      font-size: 28px;
      margin: 0 0 15px;
    }

    /*Footer*/
    .socialIcons li,
    .footerLinks li {
      margin: 0 15px;
    }

    .copyrightTxt {
      padding: 0 0 30px;
      margin-top: 80px;
    }

    p {
      letter-spacing: 0;
    }

    .contentContent p {
      font-size: 16px;
    }

    .commonContentInner + .commonContentInner {
      margin-top: 8px;
    }

    .commonContentMain::after {
      height: 10px;
    }

    .mdTitle {
      font-size: 34px;
    }

    .mobileEpkBanner {
      margin: 60px auto 90px;
    }

    .primoPlanOuter {
      border-radius: 20px;
      margin-top: 70px;
      top: 0;
      margin-bottom: 60px;
    }

    .primoPlanInner h4 {
      font-size: 20px;
    }

    .primoPlanInner {
      border-radius: 20px;
    }

    .whyWeHereContent p {
      font-size: 16px;
      letter-spacing: 0;
    }

    .fiercelyIndependentMain .mdTitle {
      font-size: 34px;
      margin-bottom: 28px;
    }

    .videoOuter p {
      font-size: 16px;
      letter-spacing: 0;
      line-height: 25px;
    }

    .whyWeHereInner {
      padding-bottom: 0;
    }

    .videoOuter {
      padding-bottom: 80px;
    }

    .mobileImg {
      width: 190px;
    }

    .mobileImg.two {
      width: 172px;
    }

    .commonContentInner + .commonContentInner .col-md-7.contentGrid {
      padding-left: 22px;
    }

    .commonContentMain .contentContent {
      padding-top: 20px;
    }

    .commonContentInner + .commonContentInner {
      padding-bottom: 44px;
    }

    .laptopFrame::before {
      left: -280px;
      height: 308px;
      width: 757px;
    }

    .luckyMacbookImg {
      width: 418px;
      left: -110px;
      top: -24px;
    }

    .whyWeHereContentInner {
      padding-right: 0;
    }
  }

  // @media only screen and (max-width: 991px) {
  //   li,
  //   p {
  //     font-size: 14px;
  //   }
  //   p {
  //     line-height: 22px;
  //   }

  //   /*Common Padding*/
  //   .smallPY {
  //     padding-top: 30px;
  //     padding-bottom: 30px;
  //   }
  //   .smallPT {
  //     padding-top: 30px;
  //   }
  //   .smallPB {
  //     padding-bottom: 30px;
  //   }
  //   .commonPY {
  //     padding-top: 50px;
  //     padding-bottom: 50px;
  //   }
  //   .commonPT {
  //     padding-top: 50px;
  //   }
  //   .commonPB {
  //     padding-bottom: 50px;
  //   }

  //   /*Common Button*/
  //   .commonButton {
  //     font-size: 14px;
  //     padding: 10px 40px 8px;
  //   }
  //   .commonButton--wide {
  //     padding-left: 60px;
  //     padding-right: 60px;
  //   }

  //   /*Sub Heading*/
  //   .subHeading h3 {
  //     font-size: 26px;
  //   }
  //   .subHeading h4 {
  //     font-size: 22px;
  //   }

  //   /*Header*/
  //   .logo img {
  //     width: 130px;
  //   }
  //   #header.fixed .logo img {
  //     width: 110px;
  //   }
  //   #header.fixed .navigationInner li {
  //     font-size: 13px;
  //   }

  //   /*Banner*/
  //   .bannerContentPart {
  //     margin-top: 60px;
  //   }
  //   .bannerContent h1 {
  //     font-size: 68px;
  //   }

  //   /*Fiercely Independent*/
  //   .bannerContent p,
  //   .smallTitle > * {
  //     font-size: 15px;
  //   }
  //   .fiercelyIndependentGrid h4 {
  //     font-size: 18px;
  //   }

  //   /*Free Music Promotion*/
  //   .freeMusicPromotionInner {
  //     max-width: 695px;
  //   }

  //   /*Why We Are Here*/
  //   .whyWeHereContent h3 {
  //     font-size: 28px;
  //     margin-bottom: 20px;
  //   }
  //   .siteLogo img {
  //     width: 130px;
  //   }

  //   /*Free Music Promotion*/
  //   .videoPart {
  //     top: 60px;
  //     /* height: 330px; */
  //   }

  //   /*Common Content*/
  //   .luckyMacbookImg {
  //     width: 400px;
  //     left: -130px;
  //   }
  //   .mobileImg {
  //     width: 200px;
  //   }
  //   .mobileImg.two {
  //     bottom: 20px;
  //   }

  //   /*Common Content*/
  //   .commonContentMain {
  //     padding-top: 140px;
  //   }
  //   .contentContent h4 {
  //     font-size: 24px;
  //     margin: 0 0 15px;
  //   }

  //   /*Footer*/
  //   .socialIcons li,
  //   .footerLinks li {
  //     margin: 0 15px;
  //   }
  //   .copyrightTxt {
  //     padding: 0 0 30px;
  //     margin-top: 80px;
  //   }
  // }
  @media only screen and (max-width: 767px) {
    li,
    p {
      font-size: 13px;
    }
    p {
      line-height: 20px;
    }

    /*Common Padding*/
    .commonPY {
      padding-top: 40px;
      padding-bottom: 40px;
    }
    .commonPT {
      padding-top: 40px;
    }
    .commonPB {
      padding-bottom: 40px;
    }

    /*Sub Heading*/
    .subHeading h3 {
      font-size: 22px;
    }
    .subHeading h4 {
      font-size: 19px;
    }

    /*Header*/
    .logo img {
      width: 110px;
    }
    #header.fixed .logo img {
      width: 90px;
    }
    #header.fixed .navigationInner li {
      font-size: 12px;
    }
    .navigationInner li + li {
      margin-left: 25px;
    }

    /*Fiercely Independent*/
    .bannerContent p,
    .smallTitle > * {
      font-size: 14px;
    }
    .fiercelyIndependentGrid h4 {
      font-size: 22px;
    }

    /*Why We Are Here*/
    .whyWeHereMain {
      background-image: url("./images/why-we-here-bg-mb.jpg") !important;
      background-position: center;
    }
    .whyWeHereInner {
      padding-bottom: 60px;
    }
    .whyWeHereContent h3 {
      font-size: 25px;
      margin-bottom: 10px;
    }
    .siteLogo.position {
      left: 0;
      right: 0;
      bottom: 10px;
      text-align: center;
    }
    .siteLogo img {
      width: 80px;
    }

    /*Free Music Promotion*/
    .freeMusicPromotionMain {
      padding-bottom: 50px;
    }

    .freeMusicPromotionInner {
      max-width: 515px;
    }

    /*Video*/
    .videoMain .videoPart {
      top: 0;
      /* max-width: 80%;
    height: 300px; */
      margin-top: 0;
    }

    /*Common Content*/
    .commonContentMain {
      padding-top: 40px;
    }
    .contentContent {
      margin-bottom: 30px;
    }
    .commonContentInner + .commonContentInner {
      margin-top: 80px;
    }
    .luckyMacbookImg {
      width: 100%;
      left: 0;
    }
    .commonContentInner .row,
    .commonContentInner:nth-child(2n) .row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }
    .commonContentInner:nth-child(2n) .contentImg {
      position: relative;
      right: 0;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
    .contentImg.doubleImg {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
    }
    .mobileImg.two {
      position: absolute;
      left: inherit;
      right: -90px;
      width: 185px;
      bottom: 30px;
      -webkit-transform: rotate(10deg);
      -ms-transform: rotate(10deg);
      transform: rotate(10deg);
    }

    /*Footer*/
    .footerLinks li {
      display: block;
      margin: 0;
      font-size: 14px;
    }
    .footerLinks li + li {
      margin-top: 20px;
    }
    .socialIcons li {
      font-size: 22px;
    }
    #footer small span {
      display: block;
    }
  }

  @media only screen and (max-width: 767px) {
    /* li, p {
    font-size: 13px;
  } */
    p {
      line-height: 20px;
    }

    /*Common Padding*/
    .commonPY {
      padding-top: 40px;
      padding-bottom: 40px;
    }

    .commonPT {
      padding-top: 40px;
    }

    .commonPB {
      padding-bottom: 40px;
    }

    /*Sub Heading*/
    .subHeading h3 {
      font-size: 22px;
    }

    .subHeading h4 {
      font-size: 19px;
    }

    /*Header*/
    .logo img {
      width: 110px;
    }

    #header.fixed .logo img {
      width: 90px;
    }

    #header.fixed .navigationInner li {
      font-size: 12px;
    }

    .navigationInner li + li {
      margin-left: 25px;
    }

    /*Fiercely Independent*/
    .bannerContent p,
    .smallTitle > * {
      font-size: 14px;
    }

    .fiercelyIndependentGrid {
      height: auto;
    }

    .fiercelyIndependentGrid h4 {
      font-size: 22px;
    }

    /*Why We Are Here*/
    .whyWeHereMain {
      background-image: url("./images/why-we-here-bg-mb.jpg") !important;
      background-position: center;
    }

    .whyWeHereInner {
      padding-bottom: 25px;
    }

    .whyWeHereContent h3 {
      font-size: 25px;
      margin-bottom: 10px;
    }

    .siteLogo.position {
      left: 0;
      right: 0;
      bottom: 22px;
      text-align: center;
    }

    .siteLogo img {
      width: 80px;
    }

    /*Free Music Promotion*/
    .freeMusicPromotionMain {
      padding-bottom: 50px;
    }

    .freeMusicPromotionMain .videoPart {
      /* display: none; */
      top: 46px;
    }

    .freeMusicPromotionInner {
      max-width: 515px;
    }

    /*Video*/
    .videoMain .videoPart {
      top: 0;
      /* max-width: 80%;
    height: 300px; */
      margin-top: 0;
    }

    /*Common Content*/
    .commonContentMain {
      padding-top: 40px;
    }

    .contentContent {
      margin-bottom: 30px;
    }

    .commonContentInner + .commonContentInner {
      margin-top: 15px;
      padding-bottom: 0;
    }

    /* .luckyMacbookImg {
    width: 100%;
    left: -167px;
  } */

    .luckyMacbookImg {
      width: 417px;
      left: -127px;
    }

    .laptopFrame::before {
      left: -298px;
    }

    .commonContentInner .row,
    .commonContentInner:nth-child(1) .row {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }

    .commonContentInner:nth-child(1) .contentImg {
      position: relative;
      right: -54px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    .contentImg.doubleImg {
      width: 100%;
      max-width: 300px;
      margin: 0 auto;
    }

    .mobileImg.two {
      position: absolute;
      left: inherit;
      right: -90px;
      width: 185px;
      bottom: 30px;
      -webkit-transform: rotate(10deg);
      -ms-transform: rotate(10deg);
      transform: rotate(10deg);
    }

    .whyWeHereMain {
      padding-bottom: 30px;
    }

    /*Footer*/
    .footerLinks li {
      display: block;
      margin: 0;
    }

    .footerLinks li + li {
      margin-top: 20px;
    }

    .socialIcons li {
      font-size: 22px;
    }

    #footer small span {
      display: block;
    }

    .mdTitle {
      font-size: 26px;
    }

    .fiercelyIndependentMain .mdTitle {
      font-size: 28px;
      margin-bottom: 24px;
    }

    .mobileEpkBanner {
      margin: 28px auto 30px;
    }

    .primoPlanOuter {
      margin-top: 55px;
      margin-bottom: 60px;
    }

    /* .videoOuter {
    padding-bottom: 2px;
  } */

    .videoOuter {
      padding-bottom: 70px;
    }

    .copyrightTxt {
      margin-top: 44px;
    }

    .commonContentMain::after {
      height: 13px;
    }

    .fiercelyIndependentGrid h4 {
      font-size: 18px;
    }

    .mobileEpkContent {
      margin: 0 auto;
      padding: 0 0px;
    }

    .primoPlanOuter .mdTitle {
      text-align: center;
    }

    li {
      font-size: 14px;
    }

    .commonContentMain .container {
      max-width: 540px;
    }

    .commonContentMain .contentContent {
      padding-top: 2px;
    }

    /* .contentImg.slideImages {
    position: relative;
    bottom: 0;
  } */

    .contentImg.slideImages {
      position: relative;
      bottom: 0px;
      margin-top: 55px;
    }

    .primoPlanInner h4 {
      font-size: 18px;
      padding-right: 0;
    }

    .rotateTitle {
      bottom: 8px;
    }
  }

  @media screen and (max-width: 767px) {
    .hv-modal .hv-modal-dialog {
      margin: 0;
      width: 100%;
      height: 100%;
      max-width: 100vw;
      max-height: 100vh;
      border-radius: 0;
    }
  }

  @media only screen and (max-width: 575px) {
    .container {
      padding-right: 20px;
      padding-left: 20px;
    }

    /*Common Padding*/
    .commonPY {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    .commonPT {
      padding-top: 30px;
    }

    .commonPB {
      padding-bottom: 30px;
    }

    /*Header*/
    .headerInner {
      position: relative;
      justify-content: center;
    }

    .logo {
      display: inline-block;
    }

    .navigationOuter {
      position: absolute;
      right: 15px;
    }

    /*Navigation*/
    .menuIcon {
      position: relative;
      z-index: 100;
      display: block;
      cursor: pointer;
      margin: 0;
    }

    .menuIcon span {
      background-color: #fff;
      position: relative;
      width: 30px;
      height: 2px;
      border-radius: 2px;
      display: block;
      margin: 0 auto;
      transition: All 0.5s ease;
      -webkit-transition: All 0.5s ease;
      -moz-transition: All 0.5s ease;
      -o-transition: All 0.5s ease;
    }

    .menuIcon span:nth-child(2) {
      margin-top: 7px;
      margin-bottom: 7px;
    }

    .menuIcon:hover span:nth-child(1),
    .menuIcon.active span:nth-child(1) {
      background-color: #f0037f;
    }

    .menuIcon:hover span:nth-child(2),
    .menuIcon.active span:nth-child(2) {
      background-color: #ffe613;
    }

    .menuIcon:hover span:nth-child(3),
    .menuIcon.active span:nth-child(3) {
      background-color: #03a0c6;
    }

    .menuIcon.active span:nth-child(1) {
      display: none;
    }

    .menuIcon.active span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    .menuIcon.active span:nth-child(3) {
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg);
      bottom: 9px;
    }

    .navigationInner {
      position: fixed;
      right: -100%;
      top: 0;
      background: #000;
      width: 260px;
      height: 100vh;
      padding: 80px 30px 30px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column;
      flex-flow: column;
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }

    .navigationInner.navToggle {
      right: 0;
    }

    .navigationInner li {
      display: block;
    }

    .navigationInner li,
    #header.fixed .navigationInner li {
      font-size: 15px;
    }

    .navigationInner li + li {
      margin-left: 0;
    }

    .navigationInner li a {
      padding: 8px 0;
    }

    .navHover {
      bottom: 5px;
    }

    /*Banner*/
    .bannerContent h1 {
      font-size: 12vw;
    }

    .bannerContent p,
    .bannerContent .buttonOuter {
      padding-left: 18px;
    }

    .bannerContent .buttonOuter {
      margin-top: 130px;
      text-align: center;
    }

    .bannerContent .commonButton {
      background-color: #b60360;
    }

    .bannerContent .commonButton:hover {
      background-color: #f0077f;
    }

    /*Fiercely Independent*/
    .bannerContent p,
    .smallTitle > * {
      font-size: 16px;
    }

    /*Video*/
    /* .videoMain .videoPart {
    height: 250px;
  } */

    /*Why We Are Here*/
    /* .whyWeHereMain {
    background-position-x: 25%;
  } */

    /*Free Music Promotion*/
    .freeMusicPromotionInner {
      max-width: 95%;
      margin-right: 0;
    }

    .freeMusicPromotionInner .subHeading {
      width: 95%;
    }

    .artistsSlider {
      width: 100vw;
    }

    /*Footer*/
    .copyrightTxt {
      padding: 0 0 15px;
      margin-top: 30px;
    }

    #footer small {
      display: block;
    }

    .copyrightTxt > * {
      font-size: 11px;
    }

    .freeMusicPromotionMain {
      padding-bottom: 40px;
    }

    .videoOuter .buttonOuter {
      margin-top: 40px;
    }

    .videoOuter.commonPT {
      padding-top: 15px;
    }

    .subHeading h3 {
      font-size: 23px;
    }

    .subHeading {
      margin: 22px 0;
    }

    .fiercelyIndependentInner {
      display: block;
    }

    .fiercelyIndependentMain .mdTitle {
      font-size: 24px;
      margin-bottom: 15px;
      text-align: center;
      padding-top: 4px;
    }

    .siteLogo.position {
      display: none;
    }

    .whyWeHereInner {
      padding-bottom: 25px;
    }

    .whyWeHereMain {
      padding-top: 40px;
    }

    .whyWeHereContent h3 {
      font-size: 24px;
      margin-bottom: 15px;
      text-align: left;
    }

    .colorBorders {
      height: 1px;
    }

    .primoPlanOuter {
      margin-top: 25px;
      margin-bottom: 50px;
      border-radius: 15px;
      padding: 18px 15px 35px;
    }

    .primoPlanInner {
      border-radius: 15px;
      padding: 28px 18px 18px;
      text-align: left;
    }

    .primoPlanInner h4 {
      font-size: 16px;
      background-position: left top;
    }

    .priceMonth {
      font-size: 37px;
      padding: 10px 0 6px;
      color: #000 !important;
      font-weight: 300;
    }

    .mobileEpkContent {
      margin: 0 auto;
      padding: 0;
      text-align: left;
    }

    .mobileEpkContent p {
      margin-bottom: 35px;
      line-height: 25px;
    }

    .mobileEpkContent p {
      margin-bottom: 35px;
      line-height: 25px;
    }

    .mobileEpk .mdTitle {
      font-size: 24px;
      margin-bottom: 13px;
      line-height: normal;
    }

    .primoPlanOuter .mdTitle {
      max-width: none;
      font-size: 26px;
    }

    .mobileEpk p {
      text-align: left;
      font-size: 16px;
    }

    .rotateTitle > * {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    .rotateTitle {
      bottom: 0;
      right: 0;
      -webkit-writing-mode: horizontal-tb;
      -ms-writing-mode: lr-tb;
      writing-mode: horizontal-tb;
      -webkit-text-orientation: mixed;
      text-orientation: mixed;
      /* -webkit-transform: translateY(-50%); */
      width: 100%;
      text-align: center;
      height: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
    }

    .fiercelyIndependentGrid {
      height: auto;
      max-height: 272px;
    }

    .fiercelyIndependentGrid .borderColors {
      left: 0;
      right: auto;
      z-index: 3;
      height: 1px;
      width: 100%;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-direction: row;
      flex-direction: row;
      bottom: 0;
      top: auto;
    }

    .fiercelyIndependentGrid h4 {
      font-size: 22px;
    }

    .fiercelyIndependentInner .item:not(:last-child) .borderColors {
      display: none;
    }

    .mob-block {
      display: block;
      font-size: 36px;
      margin-bottom: 27px;
    }

    .commonContentInner .contentContent p {
      font-size: 16px;
      line-height: 27px;
    }

    .mobText-black {
      color: #000;
    }

    .commonContentInner:nth-child(1) .contentImg {
      right: -10px;
    }

    .commonContentInner + .commonContentInner .contentImg {
      max-width: 350px;
      left: -60px;
      position: relative;
      top: 0;
    }

    .commonContentMain::after {
      height: 50px;
    }

    .contentImg.doubleImg {
      max-width: 232px;
      margin: 0 auto;
    }

    .mobileImg {
      width: 170px;
    }

    .mobileImg.two {
      right: -115px;
      bottom: 13px;
      width: 170px;
    }

    .luckyMacbookImg {
      left: -5px;
    }

    .mdTitle {
      text-align: left;
    }

    .primoPlanOuter .mdTitle {
      font-size: 29px;
    }

    .fiercelyIndependentGrid img {
      height: auto;
    }

    .bannerTitle {
      padding-left: 10px;
    }

    .bannerContent p {
      padding-left: 10px;
    }

    .bannerContent .buttonOuter {
      padding-left: 0px;
    }

    .videoOuter.commonPT {
      padding-top: 0;
      text-align: left;
    }

    .commonContentInner + .commonContentInner .col-md-7.contentGrid {
      padding-left: calc(var(--bs-gutter-x) / 2);
    }

    .primoPlanInner p {
      padding-left: 20px;
      line-height: 20px;
    }

    .laptopFrame::before {
      left: -147px;
      height: 254px;
      width: 638px;
    }

    .luckyMacbookImg {
      width: 346px;
      left: -1px;
      top: -20px;
    }

    .freeMusicPromotionMain .videoPart {
      top: 13px;
    }

    .videoOuter {
      padding-bottom: 18px;
    }
  }

  @media only screen and (max-width: 575px) {
    .form-group .hv-input:last-child {
      margin-bottom: 10px;
    }

    .form-group .hv-input {
      font-size: 16px;
    }

    label:not(.date-picker-label),
    .text-dark {
      font-size: 1rem;
    }

    .hv-modal .hv-modal-dialog .hv-modal-title {
      padding: 0 30px 0px;
      font-size: 28px;
    }

    .guest-phone .select:after {
      background-size: 10px 8px;
      width: 10px;
      right: 0;
      height: 8px;
    }
  }

  @media only screen and (max-width: 575px) {
    .contentContent h4 {
      font-size: 16px;
      margin: 0 0 21px;
      line-height: 27px;
    }
    .container {
      padding-right: 20px;
      padding-left: 20px;
    }

    /*Common Padding*/
    .commonPY {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .commonPT {
      padding-top: 30px;
    }
    .commonPB {
      padding-bottom: 30px;
    }

    /*Header*/
    .headerInner {
      position: relative;
      justify-content: center;
    }
    .logo {
      display: inline-block;
    }
    .navigationOuter {
      position: absolute;
      right: 15px;
    }

    /*Navigation*/
    .menuIcon {
      position: relative;
      z-index: 100;
      display: block;
      cursor: pointer;
      margin: 0;
    }
    .menuIcon span {
      background-color: #fff;
      position: relative;
      width: 30px;
      height: 2px;
      border-radius: 2px;
      display: block;
      margin: 0 auto;
      transition: All 0.5s ease;
      -webkit-transition: All 0.5s ease;
      -moz-transition: All 0.5s ease;
      -o-transition: All 0.5s ease;
    }
    .menuIcon span:nth-child(2) {
      margin-top: 7px;
      margin-bottom: 7px;
    }
    .menuIcon:hover span:nth-child(1),
    .menuIcon.active span:nth-child(1) {
      background-color: #f0037f;
    }
    .menuIcon:hover span:nth-child(2),
    .menuIcon.active span:nth-child(2) {
      background-color: #ffe613;
    }
    .menuIcon:hover span:nth-child(3),
    .menuIcon.active span:nth-child(3) {
      background-color: #03a0c6;
    }
    .menuIcon.active span:nth-child(1) {
      display: none;
    }
    .menuIcon.active span:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .menuIcon.active span:nth-child(3) {
      -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
      transform: rotate(135deg);
      bottom: 9px;
    }
    .navigationInner {
      position: fixed;
      right: -100%;
      top: 0;
      background: #000;
      width: 260px;
      height: 100vh;
      padding: 80px 30px 30px;
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-flow: column;
      flex-flow: column;
      -webkit-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }
    .navigationInner.navToggle {
      right: 0;
    }
    .navigationInner li {
      display: block;
    }
    .navigationInner li,
    #header.fixed .navigationInner li {
      font-size: 15px;
    }
    .navigationInner li + li {
      margin-left: 0;
    }
    .navigationInner li a {
      padding: 8px 0;
      font-size: 15px;
    }
    .navHover {
      bottom: 5px;
    }

    /*Banner*/
    .bannerContent h1 {
      font-size: 12vw;
    }
    // .bannerContent p,
    // .bannerContent .buttonOuter {
    //   padding-left: 18px;
    // }
    .bannerContent .buttonOuter {
      margin-top: 80px;
      text-align: center;
    }
    .bannerContent .commonButton {
      background-color: #b60360;
    }
    .bannerContent .commonButton:hover {
      background-color: #f0077f;
    }

    /*Fiercely Independent*/
    // .bannerContent p,
    // .smallTitle > * {
    //   font-size: 13px;
    // }

    /*Video*/
    /* .videoMain .videoPart {
    height: 250px;
  } */

    /*Why We Are Here*/
    /* .whyWeHereMain {
    background-position-x: 25%;
  } */

    /*Free Music Promotion*/
    .freeMusicPromotionInner {
      max-width: 95%;
      margin-right: 0;
    }
    .freeMusicPromotionInner .subHeading {
      width: 95%;
    }
    .artistsSlider {
      width: 100vw;
    }

    /*Footer*/
    .copyrightTxt {
      padding: 0 0 15px;
      margin-top: 30px;
    }
    #footer small {
      display: block;
    }
    .copyrightTxt > * {
      font-size: 11px;
    }
  }

  @media only screen and (max-width: 479px) {
    .radio-wrapper {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
      margin-bottom: 5px;
    }
  }

  @media only screen and (max-width: 479px) {
    /*Why We Are Here*/
    /* .whyWeHereMain {
    background-position-x: 32%;
  } */

    /*Common Content*/
    /* .commonContentInner + .commonContentInner {
    margin-top: 60px;
  } */

    /*Video*/
    /* .videoMain .videoPart {
    max-width: 90%;
  } */
    .copyrightTxt > * {
      font-size: 10px;
    }

    .commonContentInner:nth-child(1) .contentImg {
      right: 0;
    }

    .fiercelyIndependentGrid {
      height: auto;
      max-height: 198px;
    }

    .fiercelyIndependentGrid img {
      height: auto;
    }
  }

  @media only screen and (max-width: 479px) {
    /*Why We Are Here*/
    /* .whyWeHereMain {
    background-position-x: 32%;
  } */

    /*Common Content*/
    .commonContentInner + .commonContentInner {
      margin-top: 60px;
    }

    /*Video*/
    /* .videoMain .videoPart {
    max-width: 90%;
  } */
    .copyrightTxt > * {
      font-size: 10px;
    }
  }

  @media only screen and (max-width: 414px) {
    .commonContentInner:nth-child(1) .contentImg {
      right: 30px;
    }

    .commonContentInner + .commonContentInner .contentImg {
      left: -90px;
    }
  }

  @media only screen and (max-width: 360px) {
    /*Why We Are Here*/
    /* .whyWeHereMain {
    background-position-x: 42%;
  } */

    .bannerContent .buttonOuter {
      padding-left: 0;
    }

    .bannerContent .commonButton {
      width: 100%;
    }

    .mobileImg {
      width: 155px;
    }

    .mobileImg.two {
      right: -80px;
      bottom: 13px;
      width: 150px;
    }

    .mob-block {
      font-size: 34px;
    }

    .commonContentInner:nth-child(1) .contentImg {
      right: 20px;
    }

    .commonButton--wide {
      padding-left: 40px;
      padding-right: 40px;
    }

    .luckyMacbookImg {
      width: 332px;
      left: -3px;
      top: -19px;
    }

    .laptopFrame::before {
      left: -92px;
      height: 248px;
      width: 504px;
    }

    .primoPlanInner {
      padding: 25px 10px 15px;
    }

    .priceMonth {
      font-size: 32px;
    }
  }

  @media only screen and (max-width: 360px) {
    /*Why We Are Here*/
    /* .whyWeHereMain {
    background-position-x: 42%;
  } */

    .bannerContent .buttonOuter {
      padding-left: 0;
    }
    .bannerContent .commonButton {
      width: 100%;
    }
  }
}
