@import "../../../variables";
@import "../../../mixins";

.hv-modal {
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  font-family: "Noir Pro";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  height: 100vh;
  width: 100vw;
  background: rgba($color: #000000, $alpha: 0.5);
  overflow: hidden;
  z-index: 1051;
  input::placeholder {
    font-family: "Noir light";
  }
  .forget-password {
    font-size: 12px;
    line-height: 14px;
  }
  .x {
    cursor: pointer;
  }
  .hv-modal-dialog {
    border-radius: 15px;

    background: white;
    max-width: 600px;
    margin: 5vh auto;
    max-height: 90vh;
    width: 90vw;
    overflow: scroll;
    .hv-modal-title {
      padding: 0 30px 30px;
      text-align: center;
      text-transform: uppercase;
      font-style: normal;
      font-weight: 900;
      font-size: 36px;
      color: $dark;
      letter-spacing: 10px;
    }
    .hv-modal-body {
      padding: 0 10% 10%;
    }
  }
}

input.password-field {
  padding-right: 35px;
}
#welcome_modal {
  text-align: center;
  h1 {
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 43px;
  }
  p:not(.all-caps) {
    font-size: 24px;
    line-height: 29px;
    color: #000000;
  }
  .all-caps {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
  }
}
#login_modal {
  a {
    color: #01a0c6;
  }
  #login_errors {
    min-height: 50px;
    .alert {
      margin-top: 10px;
    }
  }
  #login_actions {
    margin-top: 5px;
    .btn {
      padding: 20px 30px;
      font-style: normal;
      font-weight: 900;
      font-size: 22px;
      line-height: 16px;
      letter-spacing: 2px;
      text-transform: uppercase;
      color: #ffffff;
    }
    @media (max-width: 576px) {
      .btn {
        font-size: 16px;
      }
    }
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 16px;
      color: #757575;
    }
  }
  $w: 53.67px;
  $h: 23px;
  $r: 21.05px;

  .custom-switch {
    padding-left: 40px !important;
  }

  .custom-control-label {
    color: #979797;
    padding-top: 5px;
    padding-left: 27px;
    &.remember-me {
      font-weight: 500;
      font-size: 14px;
    }
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #40a629;
    border-radius: 18px;
    width: $w !important;
    height: $h !important;
    font-size: 8px;
    content: "YES";
    font-weight: 500;
    line-height: 9px;
    padding-top: 7px;
    padding-left: 10px;
    text-align: start;
    letter-spacing: 0.15em;
  }

  .custom-switch .custom-control-label::before {
    content: "NO";
    font-weight: 500;
    line-height: 9px;
    font-size: 8px;
    color: #ffffff;
    padding-top: 7px;
    padding-right: 10px;
    letter-spacing: 0.15em;
    text-align: end;
    background-color: #ff4000;
    border: none;
    border-radius: 18px !important;
    width: $w !important;
    height: $h;
  }

  .custom-switch .custom-control-label::after {
    background-color: #ffffff !important;
    height: $r !important;
    width: $r !important;
    border-radius: 11px !important;
    top: 5px !important;
    left: -35px !important;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.5);
    border: 0.5px solid #e5e5e5;
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    left: calc(-2.25rem + 5px);
    transform: translateX($w - 23px) !important;
    height: $r !important;
    width: $r !important;
    border-radius: 11px;
  }
}
#signup {
  .form-group {
    .react-datepicker-wrapper {
      width: 100%;
    }
    margin-bottom: 10px !important;
  }
  #signup_actions {
    p {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: $default;
      a {
        color: $secondary;
      }
    }
  }
  .signup-radio{
    position: sticky;
  }
}

#userTypes-section {
  .title-radio {
    margin-bottom: 16px;
  }
  .radio-options {
    margin-bottom: 50px;
  }
  .onboarding-progress {
    margin-bottom: 50px;
  }
  .mb-input {
    margin-bottom: 50px !important;
  }
  .custom-select {
    height: calc(1.5em + 0.75rem + 5px) !important;
  }

  .custom-control {
    justify-content: center;
    align-items: center;
  }

  .custom-control-label {
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    letter-spacing: 0.02em;
  }

  .custom-control-label::after {
    top: -4px !important;
    left: -1.75rem !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
  }
  .custom-radio .custom-control-input:checked ~ .custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgb(240, 4, 127)'/%3e%3c/svg%3e") !important;
  }

  .custom-control-label::before {
    border-color: $primary;
    top: 0;
  }

  .custom-control-input:checked ~ .custom-control-label::before {
    background-color: white !important;
  }
  .custom-control-label::before {
    border-width: 1.5px !important;
  }
}

.progress-container {
  display: flex;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  width: 80%;
  .progress-point-container {
    width: fit-content;
    position: relative;
    z-index: 2;
    .progress-point {
      height: 23px;
      width: 23px;
      border-radius: 100%;
      border: 2px solid rgba(240, 4, 127, 0.4);
      background-color: white;
      margin: 0 auto;
      margin-bottom: 2px;
    }
    p {
      font-size: 10px;
      color: rgba(240, 4, 127, 0.4);
    }
  }
  .active {
    .progress-point {
      background: $primary;
      border-color: $primary;
    }
    p {
      color: $primary;
      font-weight: bold;
    }
  }
  .progress-line {
    width: 90%;
    height: 1px;
    background-color: #dadada;
    position: absolute;
    top: 17%;
    left: 5%;
  }
}

#interests-modal {
  @include change-genre-colors($secondary);
  .title {
    margin-bottom: 65px;
  }
  .title-radio {
    margin-bottom: 16px;
  }
  .radio-options {
    margin-bottom: 49px;
  }
  .onboarding-progress {
    margin-bottom: 62px;
  }
  .mb-input {
    margin-bottom: 52px !important;
  }
  .btn-add {
    position: absolute;
    height: 30px;
    width: 79px;
    padding: 5px 10px !important;
    right: 0;
    top: 0;
  }
  .btn-checkbox-container {
    height: 30px;
    margin-right: 9px;
    margin-bottom: 17px;
    display: inline-block;
    .btn {
      font-weight: bold;
      height: 29px;
      min-width: 80px;
      font-size: 10px;
      letter-spacing: 0.1px;
      border: 2px solid $secondary;
      color: $secondary;
      height: 100%;
      padding: 4px 10px !important;
      white-space: pre;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .text {
      margin-top: 1px;
    }
    .active {
      background-color: $secondary !important;
      color: white !important;
    }
  }
  .badge-pill {
    padding: 5px 8px 0 28px;
    border: none;
    outline: none;
    background-color: $dark;
    border-radius: 100px;
    width: fit-content;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 16px;
    text-transform: uppercase;
    color: #ffffff;
    height: 29px;
    margin-left: 10px;
    margin-bottom: 20px;
    button {
      background-color: transparent;
      padding: 0;
      margin-left: 14.5px;
      border: none;
      outline: none;
      vertical-align: text-bottom;
    }
  }
}
