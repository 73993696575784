* {
  margin: 0;
  padding: 0;
  -webkit-text-size-adjust: none;
  -ms-text-size-adjust: none;
  text-size-adjust: none;
}
::-webkit-scrollbar {
  background: transparent;
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #333;
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.3);
}
.onboarding-wrapper .scrollUp .headerInner {
  padding-top: 15px;
  padding-bottom: 13px;
}
#header.is-sticky-open .logo img {
  width: 180px;
}
.onboarding-wrapper .row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.onboarding-wrapper {
  font-size: 15px;
  font-weight: 400;
  color: #000;
  margin: 0;
  padding: 0;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  ol,
  ul,
  li,
  img,
  a,
  p {
    font-family: "mendl-sans-dawn", sans-serif;
    font-weight: normal;
    text-decoration: none;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  h1,
  h2,
  h3,
  h4,
  b,
  strong {
    font-weight: 800;
  }
  a:hover {
    text-decoration: none;
  }
  blockquote,
  figure {
    padding: 0;
    margin: 0;
    border-left: 0;
  }
  a,
  * a:hover {
    cursor: pointer;
    color: #000;
  }
  a:hover {
    color: #000;
  }
  * :focus {
    outline: none !important;
    box-shadow: none !important;
  }
  img {
    max-width: 100%;
  }
  a,
  input[type="submit"],
  input[type="button"],
  button {
    transition: All 0.7s ease;
    -webkit-transition: All 0.7s ease;
    -moz-transition: All 0.7s ease;
    -o-transition: All 0.7s ease;
  }
  li,
  p {
    font-size: 15px;
    color: #000;
  }

  h5,
  h6,
  p,
  small {
    font-family: "Helvetica";
  }
  p {
    line-height: 28px;
    margin-bottom: 22px;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  .fullWidth {
    display: block;
  }
  .fullImg {
    width: 100%;
  }
  main,
  header,
  section,
  footer {
    width: 100%;
    float: left;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #333;
    -webkit-transition: background-color 5000s ease-in-out 0s;
    -o-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
  }

  @media only screen and (min-width: 768px) {
    .desktop--hide {
      display: none;
    }
  }

  /*bgCover*/
  .bgCover {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .bgCover.custom {
    background-position-y: 30%;
  }
  .bgCover.top {
    background-position-y: top;
  }
  .bgCover.botton {
    background-position-y: bottom;
  }

  /*Bgstretch*/
  .bgStretch {
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  /*Background Colors*/
  .whiteBg {
    background-color: #fff;
  }
  .blackBg {
    background-color: #000;
  }

  /*Common Padding*/
  .smallPY {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .smallPT {
    padding-top: 40px;
  }
  .smallPB {
    padding-bottom: 40px;
  }
  .commonPY {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .commonPT {
    padding-top: 80px;
  }
  .commonPB {
    padding-bottom: 80px;
  }

  /*Common Button*/
  .buttonOuter {
    margin-top: 20px;
  }
  .commonButton {
    background-color: rgba(255, 255, 255, 0.1);
    position: relative;
    border: 1px solid transparent;
    color: #fff;
    display: inline-block;
    font-size: 22px;
    line-height: 27px;
    font-weight: 300;
    padding: 10px 50px 10px;
    border-radius: 10px;
    overflow: hidden;
    letter-spacing: 1.1px;
  }
  .commonButton > * {
    position: relative;
    z-index: 1;
  }
  .commonButton:hover {
    color: #fff;
    background-color: #000;
  }
  .commonButton--pink {
    background-color: #b60360;
    border-color: #b60360;
  }
  .commonButton.shine::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
    opacity: 0;
    background-repeat: no-repeat;
    background-position: -360px -60px, 0 0;
    background-image: -webkit-linear-gradient(
      to right,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 37%,
      rgba(255, 255, 255, 0.8) 45%,
      rgba(255, 255, 255, 0) 50%
    );
    background-image: -moz-linear-gradient(
      to right,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 37%,
      rgba(255, 255, 255, 0.8) 45%,
      rgba(255, 255, 255, 0) 50%
    );
    background-image: -o-linear-gradient(
      to right,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 37%,
      rgba(255, 255, 255, 0.8) 45%,
      rgba(255, 255, 255, 0) 50%
    );
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0.2) 0%,
      rgba(255, 255, 255, 0.2) 37%,
      rgba(255, 255, 255, 0.8) 45%,
      rgba(255, 255, 255, 0) 50%
    );
    -moz-background-size: 270% 250%, 100% 100%;
    background-size: 270% 250%, 100% 100%;

    -webkit-transition: background-position 0s ease;
    -moz-transition: background-position 0s ease;
    -o-transition: background-position 0s ease;
    transition: background-position 0s ease;

    -webkit-transition-duration: 1s;
    -moz-transition-duration: 1s;
    transition-duration: 1s;
  }
  .commonButton.shine:hover::before {
    width: 100%;
    // opacity: 0.6;
    opacity: 0;
    background-position: 0 0, 0 0;
  }

  /* .commonButton--pink::before {
	content:"";
	background-color: #F0077F;
	position: absolute;
	width: 0;
	height: 100%;
	bottom: 0;
	left: 0;
	transition:All 0.7s ease; 
	-webkit-transition:All 0.7s ease; 
	-moz-transition:All 0.7s ease; 
	-o-transition:All 0.7s ease;
}
.commonButton--pink:hover::before {
	width: 100%;
} 
.commonButton--pink:hover {
	border-color: #fff;
}*/

  .commonButton--wide {
    padding-left: 100px;
    padding-right: 100px;
  }
  .commonButton--fullWidth {
    width: 100%;
  }
  .commonButton--uppercase {
    text-transform: uppercase;
  }

  /*Header*/
  #header {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100;
    transition: All 1s ease;
    -webkit-transition: All 1s ease;
    -moz-transition: All 1s ease;
    -o-transition: All 1s ease;
  }
  #header.scrollUp {
    background: #000;
    box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.1);
  }
  .headerInner {
    padding: 26px 1.5%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    transition: All 0.7s ease;
    -webkit-transition: All 0.7s ease;
    -moz-transition: All 0.7s ease;
    -o-transition: All 0.7s ease;
  }
  .logo a {
    display: block;
  }
  .logo img {
    width: 232px;
    transition: All 0.7s ease;
    -webkit-transition: All 0.7s ease;
    -moz-transition: All 0.7s ease;
    -o-transition: All 0.7s ease;
  }

  /*Navbar*/
  .navigationInner li {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
  }
  .navigationInner li + li {
    margin-left: 40px;
  }
  .navigationInner li a {
    position: relative;
    color: #fff;
    display: inline-block;
    padding: 0 2px 2px 0;
    font-size: 16px;
  }
  .navigationInner li a:hover {
    color: #f0077f;
  }
  .navHover {
    content: "";
    width: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    transition: All 0.3s ease;
    -webkit-transition: All 0.3s ease;
    -moz-transition: All 0.3s ease;
    -o-transition: All 0.3s ease;
  }
  .navHover span {
    width: 3px;
    height: 3px;
    border-radius: 50%;
  }
  .navHover span:nth-child(1) {
    background-color: #03a0c6;
  }
  .navHover span:nth-child(2) {
    background-color: #ffe613;
  }
  .navHover span:nth-child(3) {
    background-color: #f0077f;
  }
  .navigationInner li a:hover .navHover {
    width: 100%;
  }

  /*Banner*/
  .bannerMain {
    position: relative;
    height: 100vh;
    overflow: hidden;
  }
  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    .bannerMain {
      max-height: 700px;
      min-height: 700px;
    }
  }
  .bannerImage {
    width: 100%;
    height: 100%;
    /* filter: blur(1px);
  	-webkit-filter: blur(1px); */
    -webkit-transform: scale(1.02);
    -ms-transform: scale(1.02);
    transform: scale(1.02);
  }
  .bannerImage img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
  }
  .bannerInner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 1;
  }
  .bannerTitle {
    position: relative;
    margin-bottom: 10px;
    padding-left: 20px;
    margin-top: 7px;
    margin-left: -5.6px;
  }
  .bannerContent p,
  .bannerContent .buttonOuter {
    padding-left: 21px;
  }
  @media (min-width: 1400px) {
    .bannerMain .container {
      max-width: 1320px;
    }

    /* .bannerContent h1 {
	font-size: 110px;
}
.bannerContent p,
.smallTitle > * {
	font-size: 22px;
} */
  }
  .bannerContent h1 {
    font-size: 80px;
    font-weight: 200;
    line-height: 100%;
    color: #fff;
    text-transform: uppercase;
  }
  .bannerContent h1 span {
    padding: 6px 0;
  }
  .bannerContent p {
    color: #fff;
  }
  .bannerContent p {
    font-size: 22px;
    padding-bottom: 15px;
    letter-spacing: 0.5px;
  }

  .smallTitle > * {
    font-size: 18px;
  }
  .smallTitle.white > * {
    color: #fff;
  }

  /*Vertical Border*/
  .borderColors {
    position: absolute;
    top: 50%;
    left: 0;
    width: 3px;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 1;
  }
  .borderColors.opacity {
    opacity: 0.6;
  }
  .borderColors.smallWd {
    width: 1px;
  }
  .borderColors.small {
    height: 92%;
  }
  .borderColors span {
    width: 100%;
    height: 100%;
  }
  .borderColors span:nth-child(1) {
    background-color: #03a0c6;
  }
  .borderColors span:nth-child(2) {
    background-color: #ffe613;
  }
  .borderColors span:nth-child(3) {
    background-color: #f0077f;
  }

  /*Horizontal Border*/
  .colorBorders {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;
    z-index: 1;
  }
  .colorBorders.opacity {
    opacity: 0.4;
  }
  .colorBorders.top {
    top: 0;
  }
  .colorBorders.bottom {
    bottom: 0;
  }
  .colorBorders span {
    width: 100%;
    height: 100%;
  }
  .colorBorders span:nth-child(1) {
    background-color: #03a0c6;
  }
  .colorBorders span:nth-child(2) {
    background-color: #ffe613;
  }
  .colorBorders span:nth-child(3) {
    background-color: #f0077f;
  }

  /*Sub Heading*/
  .subHeading {
    margin: 24px 0;
  }
  .subHeading h3 {
    font-size: 40px;
  }
  .subHeading h4 {
    font-size: 32px;
  }
  .subHeading > * {
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
  }
  .subHeading span {
    color: #f0077f;
  }

  /*Fiercely Independent*/
  .fiercelyIndependentMain .mdTitle {
    font-size: 40px;
    font-weight: 300;
    color: #ffe613;
    text-transform: uppercase;
    margin-bottom: 40px;
    padding: 4px 0;
    text-align: center;
    line-height: inherit;
  }
  .fiercelyIndependentMain .who-we-are-div {
    flex: 0 0 auto;
    width: 100%;
    h3 {
      line-item: inherit;
    }
  }
  .fiercelyIndependentMain .smallTitle {
    padding: 30px 0;
    text-align: center;
  }
  .fiercelyIndependentInner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    .slick-initialized {
      width: 100% !important;
    }
  }
  .fiercelyIndependentGrid {
    position: relative;
    width: 100%;
    height: 80vh;
    overflow: hidden;
  }
  .fiercelyIndependentGrid:hover .borderColors.opacity {
    opacity: 1;
  }
  .fiercelyIndependentGrid .borderColors {
    left: inherit;
    right: 0;
    z-index: 3;
  }
  .fiercelyIndependentGrid > a {
    position: relative;
    display: block;
    z-index: 2;
    height: 100%;
  }
  .fiercelyIndependentGrid > a::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 1;
    transition: All 1s ease;
    -webkit-transition: All 1s ease;
    -moz-transition: All 1s ease;
    -o-transition: All 1s ease;
  }
  .fiercelyIndependentGrid .item-image::before {
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 1;
    transition: All 1s ease;
    -webkit-transition: All 1s ease;
    -moz-transition: All 1s ease;
    -o-transition: All 1s ease;
  }
  .fiercelyIndependentGrid img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    transition: All 1s ease;
    -webkit-transition: All 1s ease;
    -moz-transition: All 1s ease;
    -o-transition: All 1s ease;
  }
  .fiercelyIndependentGrid > a:hover:before {
    opacity: 0;
  }
  /* .item:first-child .fiercelyIndependentGrid > a:hover img {
	-webkit-transform: scale(1.2);
	-ms-transform: scale(1.2);
	transform: scale(1.2);
} */
  .fiercelyIndependentGrid > a:hover img {
    -webkit-transform: scale(1.04);
    -ms-transform: scale(1.04);
    transform: scale(1.04);
  }
  .fiercelyIndependentGrid h4 {
    font-size: 35px;
    font-weight: 300;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  .rotateTitle {
    position: absolute;
    bottom: 20px;
    right: 15px;
    z-index: 2;
    writing-mode: vertical-lr;
    text-orientation: mixed;
    /* -webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%); */
  }
  .rotateTitle > * {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  /* mobileEpk css */

  .mobileEpk {
    p {
      color: #fff;
    }
    p {
      font-size: 20px;
      letter-spacing: 0.5px;
    }
    p {
      line-height: 28px;
      margin-bottom: 22px;
    }
    h5,
    h6,
    p,
    small {
      font-family: "Helvetica";
    }
  }

  .mobileEpk {
    padding-top: 120px;

    p {
      color: #fff;
    }

    p:last-of-type {
      margin-bottom: 0;
    }
    p {
      font-size: 20px;
      letter-spacing: 0.5px;
    }
    p {
      line-height: 28px;
      margin-bottom: 22px;
    }
    h5,
    h6,
    p,
    small {
      font-family: "Helvetica";
    }

    .mdTitle {
      font-size: 40px;
      font-weight: 300;
      text-transform: uppercase;
      margin-bottom: 20px;
      line-height: normal;
    }

    .yellowColor {
      color: #ffe613;
    }

    @media (min-width: 992px) {
      .col-lg-11 {
        width: 97.52%;
      }
    }

    @media (min-width: 992px) {
      .col-lg-11 {
        flex: 0 0 auto;
        width: 91.6666666667%;
      }
    }
    @media (min-width: 768px) {
      .col-md-12 {
        flex: 0 0 auto;
        width: 100%;
      }
    }
    .col-12 {
      flex: 0 0 auto;
      width: 100%;
    }
    .row > * {
      flex-shrink: 0;
      width: 100%;
      max-width: 100%;
      padding-right: calc(var(--bs-gutter-x) / 2);
      padding-left: calc(var(--bs-gutter-x) / 2);
      margin-top: var(--bs-gutter-y);
    }
  }

  .mobileEpk p {
    color: #fff;
  }

  .mobileEpkBanner {
    border: 1px solid #ffe613;
    border-radius: 10px;
    padding: 0;
    margin: 80px auto 120px;
  }

  .mobileEpkBanner img {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
  }

  .mobileEpkBanner video {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px;
  }

  .mobileEpkContent {
    margin: 0 auto;
    padding: 0 0px;
  }

  .primoPlanOuter {
    background: rgb(255, 230, 19);
    background: -o-linear-gradient(122deg, rgba(255, 230, 19, 1) 0%, rgba(240, 7, 127, 1) 100%);
    background: linear-gradient(328deg, rgba(255, 230, 19, 1) 0%, rgba(240, 7, 127, 1) 100%);
    border-radius: 30px;
    padding: 20px 20px 36px;
    margin: 82px auto 78px;
    position: relative;
    top: 0;
    z-index: 3;
    max-width: 800px;
    .whiteColor {
      color: #fff;
    }
    .linkColor {
      color: #f0077f;
    }
    .commonButton--black {
      background-color: #000000;
      border-color: #000000;
    }
  }

  .primoPlanOuter .mdTitle {
    font-weight: 400;
    margin-bottom: 18px;
  }

  .primoPlanInner {
    background-color: #fef2ec;
    color: #000;
    padding: 45px 30px 33px;
    border-radius: 45px;
  }

  .primoPlanContent {
    max-width: 560px;
    margin: 0 auto;
  }

  .primoPlanInner h4 {
    background-image: url(./images/pink-tik.png);
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 12px;
    font-weight: 500;
    font-size: 23px;
    letter-spacing: 0.4px;
    margin-bottom: 10px;
    display: inline-block;
    padding: 0 20px;
  }

  .primoPlanContent h4:first-child {
    background: none;
    padding: 0;
  }

  .primoPlanInner p {
    color: #000;
    margin-bottom: 15px;
    letter-spacing: 0.3px;
  }

  .primoPlanBottom {
    padding-top: 8px;
  }

  .priceMonth {
    text-transform: uppercase;
    font-size: 29px;
    padding: 10px 0 8px;
    font-weight: 400;
  }

  /*Why We Are Here*/
  .whyWeHereMain {
    position: relative;
  }
  .whyWeHereMain::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
  .whyWeHereInner {
    position: relative;
    z-index: 1;
  }
  .whyWeHereContent h3 {
    font-size: 40px;
    font-weight: 300;
    color: #ffe613;
    text-transform: uppercase;
    margin-bottom: 30px;
    line-height: normal;
  }
  .whyWeHereContent p {
    color: #fff;
    font-size: 20px;
    margin-bottom: 26px;
    letter-spacing: 0.5px;
  }
  .siteLogo.position {
    position: absolute;
    right: 5%;
    bottom: 5%;
    z-index: 1;
  }
  .siteLogo img {
    width: 180px;
  }

  /*Free Music Promotion*/
  .freeMusicPromotionInner {
    width: 100%;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
  }

  /*Artists Slider*/
  .artistsSliderInner {
    width: 100%;
    overflow: hidden;
    .slick-slide {
      width: 220px !important;
    }
  }
  .artistsSlider {
    position: relative;
    width: 100%;
  }
  .artistsSlider-item {
    max-width: 200px;
    max-height: 200px;
    margin: 0 10px;
    white-space: nowrap;
    a {
      background: #f0077f;
      width: 200px;
      height: 200px;
      display: block;
      border-radius: 8px;
      overflow: hidden;
    }
    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      pointer-events: none;
    }
  }

  @media screen and (max-width: 991px) {
    .artistsSlider-item {
      a {
        width: 170px;
        height: 170px;
      }
    }
  }
  @media screen and (max-width: 767px) {
    .artistsSlider-item {
      a {
        width: 185px;
        height: 185px;
      }
    }
  }
  @media screen and (max-width: 575px) {
    .artistsSlider-item {
      a {
        width: 160px;
        height: 160px;
      }
    }
  }
  @media screen and (max-width: 479px) {
    .artistsSlider-item {
      a {
        width: 200px;
        height: 200px;
      }
    }
  }

  /*Video*/
  .videoOuter {
    padding-bottom: 100px;
    margin-top: 15px;
    text-align: center;
  }
  .videoOuter .row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  .videoOuter p {
    color: #fff;
    font-size: 19px;
    letter-spacing: 1px;
    line-height: 32px;
  }
  .videoOuter .buttonOuter {
    text-align: center;
    margin-top: 65px;
  }

  .videoOuter .join-our-growing-div {
    flex: 0 0 auto;
    width: 91.6666666667%;
    margin: 0 auto;
  }

  .videoOuter .row {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  /* .videoPart {
	position: relative;
	top: 100px;
	width: 100%;
	max-width: 95%;
	height: 550px;
	border-radius: 0;
	overflow: hidden;
	box-shadow: 0 0 10px 0 rgba(0,0,0,0.1);
	margin: 30px auto 0;
}
.videoPart img,
.videoPart video,
.videoPart iframe {
	background-color: #f1f8fe;
	width: 100%;
	height: 100%;
	border: 0;
	border-radius: 0;
	-o-object-fit: cover;
	object-fit: cover;
	-webkit-transform: scale(1.01);
	-ms-transform: scale(1.01);
	transform: scale(1.01);
} */

  .videoPart {
    position: relative;
    top: 50px;
    padding-bottom: 56.25%;
    padding-top: 0;
    height: 0;
    margin: 30px auto 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    overflow: hidden;
  }
  .videoPart iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  /*Footer*/
  #footer {
    position: relative;
    text-align: center;
    padding-bottom: 83px;
    @media all and (max-width: 768px) {
      padding-bottom: 113px;
    }

    .footerLogo {
      padding: 33px 0 28px;
    }

    .footerLogo img {
      // width: 74px;
    }

    .commonPT {
      padding-top: 40px;
    }

    .copyrightTxt {
      padding: 0 0 30px;
      margin-top: 50px;
    }

    small {
      color: #dbdada;
    }

    .copyrightTxt > * {
      font-size: 12px;
      font-weight: 300;
      /* display: inline-block; */
      display: block;
      border-top: 1px solid rgba(255, 255, 255, 0.1);
      padding: 16px 10px 0;
    }
  }

  /*Common Content*/
  .commonContentMain {
    padding-top: 30px;
    overflow: hidden;
    position: relative;
    background-color: #f6f6f6;
    .col-md-5 {
      flex: 0 0 auto;
      max-width: 41.6666666667%;
      padding-right: calc(var(--bs-gutter-x) / 2);
      padding-left: calc(var(--bs-gutter-x) / 2);
      margin-top: var(--bs-gutter-y);
    }
    .col-md-7 {
      flex: 0 0 auto;
      max-width: 58.3333333333%;
      padding-right: calc(var(--bs-gutter-x) / 2);
      padding-left: calc(var(--bs-gutter-x) / 2);
      margin-top: var(--bs-gutter-y);
    }
  }

  .commonContentMain::after {
    content: "";
    background-color: #000;
    width: 100%;
    height: 24px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .commonContentMain .container {
    max-width: 1400px;
  }

  .commonContentMain .contentContent {
    max-width: 635px;
    padding-top: 20px;
  }

  .commonContentInner {
    position: relative;
  }

  .commonContentInner + .commonContentInner {
    margin-top: -15px;
    padding-bottom: 130px;
  }

  .commonContentInner + .commonContentInner .col-md-7.contentGrid {
    padding-left: 203px;
  }

  .commonContentInner .row {
    /* -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center; */

    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .commonContentInner:nth-child(1) .row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
  }

  .contentImg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: relative;
  }

  .contentImg.slideImages {
    position: absolute;
    top: -54px;
  }

  .doubleImg {
    right: -112px;
  }

  .contentImg img {
    position: relative;
    display: block;
    max-width: inherit;
    z-index: 1;
  }

  .contentImg.slideImages img {
    display: none;
  }

  .contentImg.slideImages img.active {
    display: block;
  }

  /* .luckyMacbookImg {
    width: 750px;
    left: -300px;
  } */

  .laptopFrame::before {
    content: "";
    background-image: url(./images/laptop.png);
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    position: absolute;
    height: 629px;
    width: 1000px;
    z-index: 1;
    bottom: 0;
    left: -342px;
  }

  /* .luckyMacbookImg {
    width: 100%;
    left: -342px;
  } */

  .luckyMacbookImg {
    width: 858px;
    left: -270px;
    top: -50px;
  }

  .mobileImg {
    width: 305px;
  }

  .mobileImg.two {
    left: -70px;
    bottom: 26px;
    z-index: 0;
    -webkit-transform: rotate(8deg);
    -ms-transform: rotate(8deg);
    transform: rotate(8deg);
    width: 290px;
  }

  .contentContent h4 {
    font-size: 40px;
    font-weight: 300;
    margin: 0 0 20px;
    letter-spacing: 0.2px;
  }

  /* @media (min-width:1550px) {
  .contentContent h4 {
    font-size: 40px;
  } 
  } */

  .pinkColor {
    color: #f0077f;
  }

  .contentContent p {
    font-size: 20px;
  }

  /*Footer Content*/

  #footer .colorBorders {
    height: 2px;
  }
  #footer p,
  #footer a,
  #footer small {
    color: #fff;
  }
  #footer small a {
    color: #ffe613;
  }
  #footer a:hover {
    color: #f0077f;
  }
  .socialIcons li,
  .footerLinks li {
    display: inline-block;
    margin: 0 15px;
  }
  .footerLinks li {
    font-size: 16px;
  }
  .socialIcons li a,
  .footerLinks li a {
    display: block;
  }
  .socialIcons li {
    font-size: 20px;
  }
  .footerLinks li {
    text-transform: uppercase;
  }
  .footerLinks li a {
    font-weight: 300;
  }
  .copyrightTxt {
    padding: 0 0 40px;
    margin-top: 100px;
  }
  .copyrightTxt > * {
    font-size: 12px;
    font-weight: 300;
    display: inline-block;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding: 20px 10px 0;
  }
  .footer-colors {
    bottom: unset;
  }
}
@media only screen and (max-width: 767px) {
  .onboarding-wrapper .commonContentMain .col-md-5 {
    flex: 0 0 auto;
    max-width: 100%;
  }
  .onboarding-wrapper .commonContentMain .col-md-7 {
    flex: 0 0 auto;
    max-width: 100%;
  }
  .onboarding-wrapper .row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
  }
  .bannerTitle {
    margin-top: 0;
    margin-left: 0;
  }
}
