@mixin change-genre-colors($color) {
  .genre-checkbox {
    .appearance {
      border: 2px solid $color;
      border-radius: 100px;
      padding: 5px 10px;
      font-weight: 600;
      min-width: 80px;
      white-space: pre;
      cursor: pointer;
      .text {
        font-size: 12px;
        line-height: 16px;
        color: $color;
        margin-top: 1px;
      }
    }
    input:checked + .appearance {
      background-color: $color;
      .text {
        color: #fff;
      }
    }
  }
}

@mixin change-switch-color($color) {
  .custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: $color;
    background-color: $color;
  }
  .custom-switch .custom-control-label::before {
    width: 50px;
    height: 20px;
    border-radius: 15px;
    border-color: #adb5bd;
    box-shadow: none;
    outline: none;
  }

  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    transform: translateX(30px);
  }
  .custom-switch .custom-control-label::after {
    cursor: pointer;
    width: 15px;
    height: 15px;
    background-color: #adb5bd;
    border-radius: 0.5rem;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }
}

@mixin hide-number-arrow() {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
