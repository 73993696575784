@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Noir Pro";
  src: local("Noir Pro"), url(./fonts/Noir/NoirPro-Regular.woff);
}

@font-face {
  font-family: "Noir bold";
  src: local("Noir bold"), url(./fonts/Noir/NoirPro-Bold.woff);
}

@font-face {
  font-family: "Noir medium";
  src: local("Noir medium"), url(./fonts/Noir/NoirPro-Medium.woff);
}

@font-face {
  font-family: "Noir light";
  src: local("Noir light"), url(./fonts/Noir/NoirPro-Light.woff);
}

@font-face {
  font-family: "Helvetica";
  src: url(./fonts/Helvetica/Helvetica.woff2) format("woff2"), url(./fonts/Helvetica/Helvetica.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: "Noir Pro" !important;
}
html,
body,
#root {
  height: 100%;
}
@import "variables";
@import "node_modules/bootstrap/scss/bootstrap";

.ml-6 {
  margin-left: $usertag-margin;
}

p {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.1px;
}
small {
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}

h1 {
  font-weight: bold;
  font-size: 36px;
  letter-spacing: 0.1px;
  font-family: "Noir bold";
}

h2 {
  font-weight: bold;
  font-size: 24px;
  line-height: 21px;
  letter-spacing: 0.1px;
}

h3 {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
}

h6 {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
}
label:not(.date-picker-label) {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  letter-spacing: 0.1px;
}

.btn {
  line-height: 16px;
  border-radius: 100px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
}

.btn-sm {
  padding: 10px 17px;
  font-size: 10px;
  font-weight: bold;
  min-width: 82px;
}

.btn-md {
  padding: 16px 20px;
  font-size: 14px;
  font-weight: bold;
  min-width: 125px;
}

.btn-lg {
  padding: 20px 38px;
  font-weight: 900;
  font-size: 18px;
  min-width: 165px;
}

.btn-xl {
  padding: 17px 66px;
  font-weight: 900;
  font-size: 18px;
}

.profile-grid-view {
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, auto));
  grid-gap: 1rem;
}

.static {
  opacity: 0.4;
}

// safari
::-webkit-credentials-auto-fill-button {
  visibility: hidden;
  pointer-events: none;
  position: absolute;
  right: 0;
}

.play-button {
  border-radius: 100px;
  box-shadow: 0 7px 15px rgba(0, 0, 0, 0.1);
}
