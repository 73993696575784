$grid-columns: 100;
$usertag-margin: 56px;
$primary: #f0047f;
$secondary: #01a0c6;
$green: #40a629;
$light: #c4c4c4;
$dark: #1a1a1a;
$default: #757575;
$orange: #ff4000;
$font-family-sans-serif: "Open Sans", sans-serif;
$yellow: #ffe610;
$bottom-bar-height: 80px;

$theme-colors: (
  "default": #757575,
  "green": $green,
  "black": #000,
  "yellow": $yellow,
);
// url("data:image/svg+xml,%3Csvg width='107' height='948' viewBox='0 0 107 948' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='100' y='0' width='23' height='237' fill='%2301A0C6'/%3E%3Crect x='100' y='237' width='7' height='237' fill='%23FFE610'/%3E%3Crect x='100' y='474' width='7' height='237' fill='%23F0047F'/%3E%3Crect x='100' y='711' width='7' height='237' fill='black'/%3E%3C/svg%3E%0A")
